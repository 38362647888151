/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* ? glassmorphism */

.glass-white {
  backdrop-filter: blur(17px) saturate(180%);
  -webkit-backdrop-filter: blur(17px) saturate(180%);
  background-color: rgba(256, 256, 256, 0.6);
}

.glass-black {
  backdrop-filter: blur(17px) saturate(180%);
  -webkit-backdrop-filter: blur(17px) saturate(180%);
  background-color: rgba(17, 25, 40, 0.5);
}

.glass-frosted {
  backdrop-filter: blur(9px) saturate(180%);
  -webkit-backdrop-filter: blur(9px) saturate(180%);
  background-color: rgba(162, 162, 162, 0.75);
}

.glass-white-2 {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(1.3px);
  -webkit-backdrop-filter: blur(1.3px);
}

/* ? glassmorphism */

.shadow-rb {
  box-shadow: 9px 9px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 9px 9px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 9px 9px 5px 0px rgba(0, 0, 0, 0.75);
}

.shadow-b {
  box-shadow: -1px 5px 5px 4px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 5px 5px 4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 5px 5px 4px rgba(0, 0, 0, 0.75);
}

/*! masonry grid css */
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  /* padding-left: 30px; gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  /* margin-bottom: 30px; */
}
/*! masonry grid css */

.form-button-gradient {
  background: linear-gradient(130deg, #80d0e7, #00b0f0);
}

/* ! MIssion Effect */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
.layers {
  width: 200px;
  max-width: 200%;
  overflow: visible;
  transform: scaleX(-1);
}
.layers g {
  transform: translateX(var(--offset-x)) translateY(var(--offset-y))
    translateZ(var(--offset-z));
  transition: transform 0.2s ease-out;
  animation: hover 2s infinite;
}
.layers g:nth-of-type(2) {
  animation-delay: 0.2s;
}
.layers g:nth-of-type(3) {
  animation-delay: 0.4s;
}
.layers g:nth-of-type(4) {
  animation-delay: 0.6s;
}
.layers text {
  font-weight: bold;
  transform: skew(-68deg, 22deg)
    translate(calc(49px * var(--size) / 100), calc(7px * var(--size) / 100))
    scaleY(0.5) scaleX(-1);
  fill: rgba(3, 3, 3, 0.8);
}
.layers path:nth-child(2) {
  fill: rgba(0, 0, 0, 0.3);
}
.layers path:nth-child(3) {
  fill: rgba(255, 255, 255, 0.3);
}
@keyframes hover {
  0%,
  100% {
    transform: translateX(var(--offset-x)) translateY(var(--offset-y))
      translateZ(var(--offset-z));
  }
  50% {
    transform: translateX(var(--offset-x))
      translateY(calc(var(--offset-y) + 3px)) translateZ(var(--offset-z));
  }
}

.bottom-misson-shadow {
  height: 40px;
  width: 40px;
  position: absolute;
  left: 50%;
  top: 43%;
  z-index: 1;
  transform: scale3d(4, -1, 3) rotate3d(1, 1, 1, 71deg);
  box-shadow: -27px -35px 33px 10px black;
}
@media (max-width: 600px) {
  .bottom-mission-shadow {
    top: 34%;
    height: 20px;
    width: 20px;
    display: none;
  }
}

/* ! MIssion Effect */

.testimonial-bg-light {
  background-image: url("./asserts/images//image4.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.testimonial-bg-dark {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./asserts//images//image4.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

/* ? Project Gallary */

.gallary-parent {
  display: grid;
  grid-template-columns: repeat(3, 300px);
  grid-template-rows: repeat(3, 300px);
  grid-column-gap: 10px;
  grid-row-gap: 5px;
}

.gc1 {
  grid-area: 1 / 1 / 2 / 2;
}
.gc2 {
  grid-area: 1 / 2 / 2 / 4;
}
.gc3 {
  grid-area: 2 / 1 / 3 / 2;
}
.gc4 {
  grid-area: 3 / 1 / 4 / 2;
}
.gc5 {
  grid-area: 2 / 2 / 3 / 3;
}
.gc6 {
  grid-area: 3 / 2 / 4 / 3;
}
.gc7 {
  grid-area: 2 / 3 / 4 / 4;
}

/* ? Project Gallary */

/* 
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 100%;
  height: 100%;
} */

/* .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

/* ? media queries */
.contact-us {
  width: 417px;
  height: 352px;
}

.apply-form {
  width: 845px;
}

.testimonial-card {
  width: 725px;
  height: 436px;
}

.testimonial-img {
  /* width: 317px; */
  width: 50%;
  height: 377px;
}

.testimonials-video-card {
  width: 725px;
  height: 436px;
}

.home-project-glass-card {
  width: 584px;
}

.aboutus-info-card {
  width: 553px;
}

.job-description-card {
  width: 726px;
  height: 407px;
  border-radius: 50px;
}

.aboutus-mission-card {
  /* width: ; */
}

@media only screen and (max-width: 476px) {
  .contact-us {
    width: 80%;
  }
}

@media only screen and (max-width: 642px) {
  .home-project-glass-card {
    width: 80%;
  }

  .aboutus-info-card {
    width: 90vw;
  }
}

@media only screen and (max-width: 748px) {
  .job-description-card {
    width: 90%;
  }
}

@media only screen and (max-width: 892px) {
  .apply-form {
    width: 80%;
  }
}

@media only screen and (max-width: 1024px) {
  .testimonial-img {
    height: 50%;
  }
}

@media only screen and (max-width: 528px) {
  .apply-form {
    width: 90%;
  }
}

@media only screen and (max-width: 778px) {
  .testimonials-video-card {
    width: 80vw;
  }
}

@media only screen and (max-width: 802px) {
  .testimonial-card {
    width: 320px;
    margin: auto;
    min-height: 377px;
  }

  .testimonial-img {
    height: 50%;
    width: 100%;
  }
}

@media only screen and (max-width: 990px) {
  .gallary-parent {
    display: grid;
    grid-template-columns: repeat(3, 200px);
    grid-template-rows: repeat(3, 200px);
    grid-column-gap: 10px;
    grid-row-gap: 5px;
  }
}

@media only screen and (max-width: 660px) {
  .gallary-parent {
    display: grid;
    grid-template-columns: repeat(2, 200px);
    grid-template-rows: repeat(5, 200px);
    grid-column-gap: 10px;
    grid-row-gap: 5px;
  }

  .gc1 {
    grid-area: 1 / 1 / 2 / 3;
  }
  .gc2 {
    grid-area: 2 / 1 / 4 / 2;
  }
  .gc3 {
    grid-area: 2 / 2 / 3 / 3;
  }
  .gc4 {
    grid-area: 3 / 2 / 4 / 3;
  }
  .gc5 {
    grid-area: 4 / 1 / 5 / 2;
  }
  .gc6 {
    grid-area: 4 / 2 / 5 / 3;
  }
  .gc7 {
    grid-area: 5 / 1 / 6 / 3;
  }
}

@media only screen and (max-width: 420px) {
  .gallary-parent {
    display: grid;
    grid-template-columns: repeat(2, 150px);
    grid-template-rows: repeat(5, 150px);
    grid-column-gap: 10px;
    grid-row-gap: 5px;
  }
}

/* ? media queries */

/* ? rough */
.rcheck {
  @apply 2xl:bg-red-300 xl:bg-blue-300 lg:bg-green-300 md:bg-yellow-200 sm:bg-pink-500 bg-blue-300;
}

.video-background {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}
.video-background iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%);
}
@media (min-aspect-ratio: 1.7777777778) {
  .video-background iframe {
    height: 56.25vw;
  }
}
@media (max-aspect-ratio: 1.7777777778) {
  .video-background iframe {
    width: 177.78vh;
  }
}
/* ? rough */
